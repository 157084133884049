import React,{useState, useRef, useEffect} from "react";
import classes from "./CodeVerification.module.scss";
import theme from "../../../remitbee/theme/Theme";
import { useMediaQuery } from "@mui/material";

interface Props {
  length: any,
  onComplete: (e) => void,
  placeholder?: string,
  error?: boolean,
}

const CodeVerificationInput: React.FC<Props> = ({ length, onComplete, placeholder, error = false }) => {
    const [code, setCode] = useState([...Array(length).fill("")]);
  const codeRef = useRef([]);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);

  const handleCharChange = (event, index) => {
    const value = event.target.value;
    if (/[^0-9]/.test(value)) return;
    // Detecting autofill by checking if multiple digits are inserted at once
    if (value.length > 1 && value.length <= length && index !== length - 1 && isMobile) {
      const splitCode = value.slice(0, length).split("")
      setCode(splitCode);
      onComplete(splitCode.join(""));
    } else {
      const newCode = [...code];
      newCode[index] = value[0];
      setCode(newCode);
      if (index < length - 1 && value) {
        codeRef.current[index + 1].focus();
      }
      if (newCode.every(char => char !== "")) {
        onComplete(newCode.join(""));
      }
    }
  }

  const handleKeyChange = (event, index) => {
    if (event.keyCode === 8 && index !== 0) {
      const newCode = [...code];
      newCode[index] = "";
      setCode(newCode);
      if (codeRef.current[index - 1]) codeRef?.current[index - 1]?.focus();
    }
  }

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');
    const newCode = [...pastedData];
    if(newCode && newCode.length <= 6 && newCode.every(val => parseInt(val) >= 0) ) {
    setCode(newCode);
    onComplete(newCode.join(""));
    if (length > 0) codeRef.current[length - 1]?.focus();
    } 
  }

    return (
        <div className={classes['rb-root']}>
            {
                code.map((item, index) => {
                    return (
                        <input 
                        id={`code-${index+1}`}
                        className={`${classes['rb-input']} ${error && classes['rb-input-error']}`}
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        value={item}
                        style={{ width: '100%' }}
                        placeholder={placeholder}
                        onKeyUp={(e) => handleKeyChange(e, index)}
                        ref={(ref) => codeRef.current.push(ref)}
                        onChange={(e) => handleCharChange(e, index)}
                        autoFocus={ index === 0}
                        onPaste={handlePaste}
                        onInput={(e) => handleCharChange(e, index)}
                         />
                    )
                })
            }
           
        </div>
    )
}

export default CodeVerificationInput;