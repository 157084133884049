import React from 'react';
import showPasswordIcon from '../../../../public/design-system/password/showPassword.svg'
import hidePasswordIcon from '../../../../public/design-system/password/hidePassword.svg'


const TogglePassword = ({showPassword}) => {
  return (
    <img src={showPassword ? hidePasswordIcon : showPasswordIcon} alt='visibility' />
  )
}

export default TogglePassword